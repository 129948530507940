
import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'

const Footer = () => (
  <Row noGutters className="footer align-items-center">
    <Container>
      <Row noGutters className="mx-auto">
        <Col md={2} sm={2} xs={2} lg={2} className="footer-title">
          <StaticImage src="../images/logo/icon-circle.png"
          />
        </Col>
        <Col md={1} sm={1} xs={1} lg={1} className="footer-title">

        </Col>
        <Col md={3} sm={3} xs={3} lg={3} className="footer-title">
          <Link to="/#home" className="footer-link"><p>HOME</p></Link>
          <Link to="/#photobooth" className="footer-link"><p>PHOTOBOOTH</p></Link>
          <Link to="/#fitur" className="footer-link"><p>FITUR</p></Link>
        </Col>
        <Col md={3} sm={3} xs={3} lg={3} className="footer-title">
          <Link to="/#pricing" className="footer-link"><p>HARGA</p></Link>
          <Link to="/#howto" className="footer-link"><p>HOW TO ORDER</p></Link>
          <Link to="/#gallery" className="footer-link"><p>GALLERY</p></Link>
        </Col>
        <Col md={3} sm={3} xs={3} lg={3} className="footer-title">
          <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100} >
            <Row noGutters className="align-items-center mx-auto">
              <p>Yogyakarta, Indonesia</p>
            </Row>
            <Row noGutters className="align-items-center mx-auto">
              <Link to="mailto:sudutkolase@gmail.com" className="footer-link">
                <FontAwesomeIcon icon={faEnvelope} size="2x" className="footer-social-icon"/>
              </Link>
              <Link to="https://instagram.com/sudutkolase.id" className="footer-link">
                <FontAwesomeIcon icon={faInstagram} size="2x" className="footer-social-icon"/>
              </Link>
              <Link to="https://wa.me/+6285155456254" className="footer-link">
                <FontAwesomeIcon icon={faWhatsapp} size="2x" className="footer-social-icon"/>
              </Link>
            </Row>
          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100} >
            <Row noGutters className="align-items-center mx-auto">
            </Row>
          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100} >
            <Row className="align-items-center mx-auto">
            </Row>
          </ScrollAnimation>
        </Col>

      </Row>
      <Row noGutters className="mx-auto">
        <Col md={12} sm={12} lg={12} >
          <p>© 2022. Sudut Kolase. All Rights Reserved. </p>
        </Col>
      </Row>


    </Container>
  </Row>
)

export default Footer
