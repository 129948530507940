
import React from "react"
import { Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import Gallery from "react-photo-gallery"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "Home" }}>
    <Seo title="Photobooth Instan" keywords={[`instant photo`, `photo booth`, `wedding`]} />
    <Row id="home" noGutters className="photobooth-banner">
      <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={100}>
        <Row className="photobooth-banner-scroll">
          <Col md={2} sm={2} xs={2} lg={2} className="photobooth-banner-logo">
            <StaticImage src="../images/logo/icon-circle.png"
            />
          </Col>
        </Row>
        <Row className="photobooth-banner-scroll">
          <Col md={6} sm={6} xs={6} lg={6} className="photobooth-banner-text">
            <h1 className="display-5">Photobooth untuk Semua Acaramu!</h1>
            <p className="">SUDUTKOLASE.ID</p>
          </Col>
        </Row>
        <Row>
          <Col md={1} sm={1} lg={1} className="photobooth-banner-line">
          </Col>
          <Col md={2} sm={2} lg={2}>
            <p className="photobooth-banner-line-text">SCROLL⟶</p>
          </Col>
        </Row>
      </ScrollAnimation>
    </Row>

    <Row id="photobooth" noGutters className="photobooth-banner-second">
      <Col md={3}></Col>
      <Col md={6} className="photobooth-banner-second-text">
        <ScrollAnimation animateOnce={false} animateIn="fadeInUp" delay={100}>
          <h1>Kenapa Photobooth?</h1>
          <h1>_</h1>
          <p className="text-justify">Ketika seseorang telah berdandan dan perpakaian rapi, mereka dapat membuat pengalaman yang unik dan tak terlupakan. menggunakan fotobooth pada saat acara pernikahan adalah salah satunya.</p>
          <p className="text-justify">
          Ada banyak kemungkinan bahwa tamu anda tidak terlalu suka mengikuti hiburan atau makanan yang disediakan. Atau sekedar menghindari rasa canggung dalam mengantri. fotobooth bisa menjadi salah satu solusinya.</p>
          <p className="text-justify">
          Memberikan kenangan pada pengunjung acara sangatlah penting, souvenir memang menjadi solusi utama selama ini. Namun, bayangkan jika kenangan yang diberikan bisa sesuai dengan keinginan tiap orang. Dengan fotobooth semua orang bisa berpose dengan sesuai keinginan mereka.</p>
        </ScrollAnimation>
      </Col>
      <Col md={3}></Col>
    </Row>

    <Row id="fitur" noGutters className="photobooth-features">
      <Row noGutters className="photobooth-features-one">
        <Col md={3}></Col>
        <Col md={6} className="photobooth-banner-second-text">
          <ScrollAnimation animateOnce={false} animateIn="fadeInUp" delay={100}>
            <Row noGutters>
              <Col md={6} className="photobooth-features-photo">
                <StaticImage src="../images/photo_01.jpeg"
                />
              </Col>
              <Col md={6} className="photobooth-features-text">
                <div className="photobooth-features-text-data">
                  <h4>CETAK 1 MENIT</h4>
                  <p>Dengan teknologi masa kini, proses cetak foto dapat ditunggu dengan cepat kurang lebih 1 menit saja, jadi dapat meminimalisir antrian yang menumpuk ya teman</p>
                </div>
              </Col>
            </Row>
          </ScrollAnimation>
        </Col>
        <Col md={3}></Col>
      </Row>

      <Row noGutters className="photobooth-features-two">
        <Col md={3}></Col>
        <Col md={6} className="photobooth-banner-second-text">
          <ScrollAnimation animateOnce={false} animateIn="fadeInUp" delay={100}>
            <Row noGutters>
              <Col md={6} className="photobooth-features-photo">
                <StaticImage src="../images/photo_02.jpeg"
                />
              </Col>
              <Col md={6} className="photobooth-features-text">
                <div className="photobooth-features-text-data">
                  <h4>LAYAR POSE</h4>
                  <p>Pengunjung dapat melihat pose foto melalui layar, jadi dapat mengatur pose dengan cepat dan mudah</p>
                </div>
              </Col>
            </Row>
          </ScrollAnimation>
        </Col>
        <Col md={3}></Col>
      </Row>

      <Row noGutters className="photobooth-features-three">
        <Col md={3}></Col>
        <Col md={6} className="photobooth-banner-second-text">
          <ScrollAnimation animateOnce={false} animateIn="fadeInUp" delay={100}>
            <Row noGutters>
              <Col md={6} className="photobooth-features-photo">
                <StaticImage src="../images/photo_06.jpg"
                />
              </Col>
              <Col md={6} className="photobooth-features-text">
                <div className="photobooth-features-text-data">
                  <h4>KAMERA MIRRORLESS</h4>
                  <p>Dengan kamera canggih dan integrasi dengan sistem, maka hasil foto akan semakin indah dalam mengabadikan momen berharga anda</p>
                </div>
              </Col>
            </Row>
          </ScrollAnimation>
        </Col>
        <Col md={3}></Col>
      </Row>

      <Row noGutters className="photobooth-features-four">
        <Col md={3}></Col>
        <Col md={6} className="photobooth-banner-second-text">
          <ScrollAnimation animateOnce={false} animateIn="fadeInUp" delay={100}>
            <Row noGutters>
              <Col md={6} className="photobooth-features-photo">
                <StaticImage src="../images/photo_07.jpg"
                />
              </Col>
              <Col md={6} className="photobooth-features-text">
                <div className="photobooth-features-text-data">
                  <h4>SCAN QR UNTUK DOWNLOAD</h4>
                  <p>Dengan sistem cloud kami, pengguna photobooth dapat mengunduh file foto dengan cara scan QR-Code yang tertera pada foto, sangat praktis dan mudah</p>
                </div>
              </Col>
            </Row>
          </ScrollAnimation>
        </Col>
        <Col md={3}></Col>
      </Row>
    </Row>

    <Row id="pricing" noGutters className="photobooth-pricing">
      <Col md={3}></Col>
      <Col md={6} className="photobooth-pricing-text">
        <ScrollAnimation animateOnce={false} animateIn="fadeInUp" delay={100}>
          <h1>Berapa Biaya Jasa Photobooth?</h1>
          <h1>_</h1>
          <h2 className="text-justify">IDR 2.500.000,- </h2>
          <h4 className="text-justify">Untuk 2 Jam Unlimited Foto</h4>
          <p className="text-justify">Tiap tambahan jam, akan dikenakan fee IDR 750.000,-</p>
          <small className="text-justify">*Belum termasuk biaya transportasi di luar Yogyakarta</small>
        </ScrollAnimation>
      </Col>
      <Col md={3}></Col>
    </Row>

    <Row id="howto" noGutters className="photobooth-howto">
      <Col md={3}></Col>
      <Col md={6} className="photobooth-pricing-text">
        <ScrollAnimation animateOnce={false} animateIn="fadeInUp" delay={100}>
          <h1>Bagaimana Cara Memesan?</h1>
          <h1>_</h1>
          <h4 className="">Hubungi kami melalui <a className="photobooth-howto-a" href="https://wa.me/+6285155456254">Whatsapp (+6285155456254)</a>, <a className="photobooth-howto-a" href="https://instagram.com/sudutkolase.id">Instagram (@sudutkolase.id)</a>, atau <a className="photobooth-howto-a" href="mailto:sudutkolase@gmail.com">Email (sudutkolase@gmail.com)</a> :D</h4>
          <small>*follow instagram kami untuk info discount dan voucher</small>
        </ScrollAnimation>
      </Col>
      <Col md={3}></Col>
    </Row>

    <div id="gallery">
      <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
        <Masonry>
          <StaticImage src="../images/gallery/DSC09812.jpg"/>
          <StaticImage src="../images/gallery/DSC09803.jpg"/>
          <StaticImage src="../images/gallery/IMG_0589.jpg"/>
          <StaticImage src="../images/gallery/IMG_0603.jpg"/>
          <StaticImage src="../images/gallery/IMG_0607.jpg"/>
          <StaticImage src="../images/gallery/IMG_0631.jpg"/>
          <StaticImage src="../images/gallery/DSC09805.jpg"/>
          <StaticImage src="../images/gallery/DSC09813.jpg"/>
          <StaticImage src="../images/gallery/IMG_0597.jpg"/>
          <StaticImage src="../images/gallery/IMG_0604.jpg"/>
          <StaticImage src="../images/gallery/IMG_0625.jpg"/>
          <StaticImage src="../images/gallery/DSC09808.jpg"/>
          <StaticImage src="../images/gallery/DSC09814.jpg"/>
          <StaticImage src="../images/gallery/IMG_0600.jpg"/>
          <StaticImage src="../images/gallery/IMG_0605.jpg"/>
          <StaticImage src="../images/gallery/IMG_0626.jpg"/>
          <StaticImage src="../images/gallery/DSC09810.jpg"/>
          <StaticImage src="../images/gallery/IMG_0586.jpg"/>
          <StaticImage src="../images/gallery/IMG_0602.jpg"/>
          <StaticImage src="../images/gallery/IMG_0606.jpg"/>
          <StaticImage src="../images/gallery/IMG_0630.jpg"/>
        </Masonry>
      </ResponsiveMasonry>
    </div>

    <Footer />
  </Layout>
)

export default IndexPage
